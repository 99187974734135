.sexy_line {
  margin: 15px 0;
  height: 1px;
  background: white;
  /*background: -webkit-gradient(linear, 0 0, 100% 0, from(white), to(white), color-stop(50%, darkgray));*/
}
#brand {
  margin-right: 60px;
  font-size: 24px;
  color: darkslategrey;
  /*
    background: -webkit-linear-gradient(left, firebrick, chocolate);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    */
}

#login-link:empty {
  display: none;
}

#login-link {
  max-height: 20px;
  overflow: hidden;
}

#login-link,
#signedin {
  margin-left: auto;
  margin-right: 20px;
}

a#username {
  margin-right: 0;
}
