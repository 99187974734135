.sexy_line {
  height: 1px;
  background: #fff;
  margin: 15px 0;
}

#brand {
  color: #2f4f4f;
  margin-right: 60px;
  font-size: 24px;
}

#login-link:empty {
  display: none;
}

#login-link {
  max-height: 20px;
  overflow: hidden;
}

#login-link, #signedin {
  margin-left: auto;
  margin-right: 20px;
}

a#username {
  margin-right: 0;
}

/*# sourceMappingURL=index.fdc31afd.css.map */
